import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, Navigate } from "react-router-dom";

import {
      DropdownContent,
      DropdownHeader,
      DropdownMenu,
      DropdownToggle,
      HeaderFunction,
      HeaderLeftBar,
      HeaderNotification,
      HeaderProfile,
      HeaderProfileContent,
      HeaderProfileDetail,
      MainContainer,
      MainContent,
      MainHeader,
      ProfileLink,
} from "../../../styles/layouts/header";
import axios from "axios";
import CheckPermission from "../../../components/roles/CheckPermission";

const Header = ({ children }) => {
      const [user, setUser] = useState([]);
      const [loading, setLoading] = useState(false);
      const login = localStorage.getItem("isLoggedIn");
      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/auth/profile`, {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  })
                  .then((response) => {
                        setUser(response.data.user);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      function onLogoutHandler() {
            localStorage.clear();
      }

      useEffect(() => {
            if (token == null) {
                  onLogoutHandler();
            }
      });

      if (login === null) {
            return <Navigate to="/" />;
      }

      return (
            <>
                  <MainContent>
                        <MainContainer>
                              <MainHeader>
                                    <HeaderLeftBar>
                                          <Link
                                                target="_blank"
                                                to="https://bathnroom.com"
                                          >
                                                Visit Site
                                          </Link>
                                    </HeaderLeftBar>
                                    <HeaderFunction>
                                          <HeaderNotification>
                                                <Dropdown>
                                                      <DropdownToggle>
                                                            <span className="material-icons">
                                                                  notifications
                                                            </span>
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                            <DropdownHeader>
                                                                  <h4 className="text-light">
                                                                        Notifications
                                                                  </h4>
                                                            </DropdownHeader>
                                                            <DropdownContent>
                                                                  <ul>
                                                                        <li>
                                                                              <a>
                                                                                    1.
                                                                                    An
                                                                                    Order
                                                                                    has
                                                                                    been
                                                                                    placed
                                                                              </a>
                                                                        </li>
                                                                  </ul>
                                                            </DropdownContent>
                                                      </DropdownMenu>
                                                </Dropdown>
                                          </HeaderNotification>
                                          <HeaderProfile>
                                                <img
                                                      src="/images/favicon.jpg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                                <HeaderProfileContent>
                                                      <p>
                                                            Welcome <br />
                                                            {user.name}
                                                      </p>
                                                      <HeaderProfileDetail>
                                                            <ProfileLink to="/dashboard/profile">
                                                                  <span class="material-icons">
                                                                        person
                                                                  </span>
                                                                  My Profile
                                                            </ProfileLink>
                                                            {!loading ? (
                                                                  <CheckPermission
                                                                        userPermissions={
                                                                              user.permissions
                                                                        }
                                                                        permission="company-info"
                                                                  >
                                                                        <ProfileLink to="/company-information">
                                                                              <span class="material-icons">
                                                                                    person
                                                                              </span>
                                                                              Company
                                                                              Info
                                                                        </ProfileLink>
                                                                  </CheckPermission>
                                                            ) : null}
                                                            <ProfileLink to="settings">
                                                                  <span class="material-icons">
                                                                        settings
                                                                  </span>
                                                                  Settings
                                                            </ProfileLink>
                                                            <ProfileLink
                                                                  role="button"
                                                                  onClick={
                                                                        onLogoutHandler
                                                                  }
                                                            >
                                                                  <span class="material-icons">
                                                                        logout
                                                                  </span>
                                                                  Log out
                                                            </ProfileLink>
                                                      </HeaderProfileDetail>
                                                </HeaderProfileContent>
                                          </HeaderProfile>
                                    </HeaderFunction>
                              </MainHeader>
                              {children}
                        </MainContainer>
                        <footer class="main-footer mt-5 text-start bg-white border rounded py-2 px-3">
                              <div>
                                    Powered by{" "}
                                    <a
                                          style={{ color: "#E86B19" }}
                                          href="https://www.infinityinfosys.com"
                                    >
                                          {" "}
                                          Infinity Infosys Pvt. Ltd.
                                    </a>
                              </div>
                        </footer>
                  </MainContent>
            </>
      );
};

export default Header;
