import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import axios from 'axios';

import Progress from 'react-progress-2';
import TableSearch from '../../components/Form/Search';
import TableFooter from '../../components/Form/TableFooter';
import { Loading } from '../../components/Loading';
import ProductImage from '../../components/Form/ProductImage';
import { Link } from 'react-router-dom';
import { ShowIcon } from '../../components/icons';
import { LinkButton } from '../../styles/components/common/Button';
import { toast } from 'react-hot-toast';
import DeleteButton from '../../components/commons/DeleteButton';

const QuotationList = () => {
      const [quotations, setQuotations] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalQuotations, setTotalQuotations] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [userPermissions, setUserPermissions] = useState([]);

      const [user, setUser] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotation_requests?limit=${limit}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setQuotations(response.data.quotations);
                              setTotalQuotations(response.data.totalQuotations);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/auth/profile`, {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  })
                  .then((response) => {
                        setUser(response.data.user);
                        setUserPermissions(response.data.user.permissions);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit]);

      async function getQuotationData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotation_requests?page=${pageNumber}&limit=${limit}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setQuotations(response.data.quotations);
                        setTotalQuotations(response.data.totalQuotations);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (quotation) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotation_requests/${quotation.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setQuotations((quotations) =>
                                    quotations.filter(
                                          (item) => item.id !== quotation.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Quotation List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/quotations/products">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch setLimit={setLimit} />

                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>
                                                                  Contact Person
                                                            </th>
                                                            <th>By Office</th>
                                                            <th>Total items</th>
                                                            <th>Order Time</th>
                                                            <th>Status</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            quotations.length >
                                                            0 ? (
                                                                  quotations.map(
                                                                        (
                                                                              quotation,
                                                                              index
                                                                        ) => (
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                quotation.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      quotation.contact_person
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                quotation.user_name
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                quotation.total_items
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                quotation.date
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                quotation.status
                                                                                          }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          {/* <Link
                                                                                                to={`/dashboard/quotations/${
                                                                                                      quotation.slug
                                                                                                }/${
                                                                                                      user.role ===
                                                                                                            'super admin' ||
                                                                                                      user.role ===
                                                                                                            'manager'
                                                                                                            ? 'edit'
                                                                                                            : 'show'
                                                                                                }`}
                                                                                                className="text-success mx-2"
                                                                                          >
                                                                                                <ShowIcon />
                                                                                          </Link> */}
                                                                                          <Link
                                                                                                to={`/dashboard/quotations/${quotation.slug}/edit`}
                                                                                                className="text-success mx-2"
                                                                                          >
                                                                                                <ShowIcon />
                                                                                          </Link>
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      quotation
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan={
                                                                                          7
                                                                                    }
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Quotation
                                                                                    Found
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    7
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              <Loading />
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>

                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalQuotations}
                                                currentPage={currentPage}
                                                getData={getQuotationData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default QuotationList;
