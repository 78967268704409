import React from "react";
import { Route, Routes } from "react-router-dom";
import ProductList from "../backend/products/ProductList";
import {
      BlogCategoryCreate,
      BlogCategoryEdit,
      BlogCategoryList,
} from "../backend/blogCategory";
import {
      BrandCreate,
      BrandEdit,
      BrandExport,
      BrandList,
      BrandManage,
      BrandShow,
} from "../backend/brands";
import {
      CategoryList,
      CategoryCreate,
      CategoryEdit,
      CategoryShow,
} from "../backend/category";

import QuotationShow from "../backend/quotations/QuotationShow";
import QuotationList from "../backend/quotations/QuotationList";
import QuotationCreate from "../backend/quotations/QuotationCreate";
import QuotationProduct from "../backend/quotations/QuotationProduct";

import { BlogList, BlogEdit, BlogCreate, BlogShow } from "../backend/blogs";
import { JobCreate, JobList, JobEdit, JobShow } from "../backend/jobs";
import { FaqCreate, FaqEdit, FaqList, FaqShow } from "../backend/faqs";
import { FaqTypeCreate, FaqTypeEdit, FaqTypeList } from "../backend/faqType";

import {
      PluginCreate,
      PluginEdit,
      PluginList,
      PluginShow,
} from "../backend/plugins";
import {
      TestimonialCreate,
      TestimonialEdit,
      TestimonialList,
      TestimonialShow,
} from "../backend/testimonials";
import {
      SubscriberCreate,
      SubscriberEdit,
      SubscriberList,
} from "../backend/subscribers";
import {
      ProductCreate,
      ProductEdit,
      ProductImportCreate,
      ProductImportUpdate,
      ProductShow,
} from "../backend/products";
import { RoleCreate, RoleEdit, RoleList } from "../backend/roles";
import { UserCreate, UserEdit, UserList, UserShow } from "../backend/users";
import {
      CatalogueCreate,
      CatalogueEdit,
      CatalogueList,
      CatalogueShow,
} from "../backend/catalogues";
import { PageCreate, PageEdit, PageList, PageShow } from "../backend/pages";
import {
      ComplainTypeCreate,
      ComplainTypeEdit,
      ComplainTypeList,
      ComplainTypeShow,
} from "../backend/complainTypes";
import {
      FaqRequestEdit,
      FaqRequestList,
      FaqRequestShow,
} from "../backend/faqRequest";
import {
      QuotationApproved,
      QuotationCancelled,
      QuotationPending,
      QuotationRejected,
      QuotationView,
} from "../backend/quotations";
import { ComplainList, ComplainShow } from "../backend/complains";
import { CustomerEnquiryList, CustomerEnquiryShow } from "../backend/enquiries";
import { UnitCreate, UnitEdit, UnitList, UnitShow } from "../backend/units";
import { SizeList, SizeCreate, SizeShow, SizeEdit } from "../backend/sizes";
import {
      ColorCreate,
      ColorEdit,
      ColorList,
      ColorShow,
} from "../backend/colors";
import {
      BannerCreate,
      BannerEdit,
      BannerList,
      BannerShow,
} from "../backend/banners";
import Dashboard from "../backend/dashboard";
import PageNotFound from "../PageNotFound";
import {
      ContactCreate,
      ContactEdit,
      ContactList,
      ContactShow,
} from "../backend/contact";
import Profile from "../profile/Profile";
import ChangePassword from "../profile/ChangePassword";
import {
      CompanyCreate,
      CompanyEdit,
      CompanyList,
      CompanyShow,
} from "../backend/shops";
import ImageCreate from "../backend/images/ImageCreate";
import {
      ShowroomCreate,
      ShowroomEdit,
      ShowroomList,
      ShowroomShow,
} from "../backend/showrooms";
import {
      ShowroomCollectionCreate,
      ShowroomCollectionEdit,
      ShowroomCollectionList,
      ShowroomCollectionShow,
} from "../backend/showroom_collections";
import { CandidateList, CandidateShow } from "../backend/candidates";
import { ContactFormList, ContactFormShow } from "../backend/contactForms";
import {
      CustomerCreate,
      CustomerEdit,
      CustomerList,
      CustomerShow,
} from "../backend/customers";
import {
      DealerEnquiryList,
      DealerEnquiryShow,
} from "../backend/dealer_enquiries";
import {
      DealerRequestList,
      DealerRequestShow,
} from "../backend/dealer_requests";
import { DealerList, DealerShow } from "../backend/dealers";
import {
      AllOrder,
      CompletedOrder,
      OrderShow,
      PendingOrder,
      ProcessingOrder,
      RejectedOrder,
} from "../backend/orders";
import PendingShow from "../backend/orders/PendingShow";
import ProcessingShow from "../backend/orders/ProcessingShow";
import RejectedShow from "../backend/orders/RejectedShow";
import CancelledOrder from "../backend/orders/CancelledOrder";
import CancelledShow from "../backend/orders/CancelledShow";
import ShippingOrder from "../backend/orders/ShippingOrder";
import ShippingShow from "../backend/orders/ShippingShow";
import CompletedShow from "../backend/orders/CompletedShow";
import {
      TrendingProductCreate,
      TrendingProductEdit,
      TrendingProductList,
      TrendingProductNew,
      TrendingProductShow,
} from "../backend/trending_products";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        {/* Category */}
                        <Route
                              path="/dashboard/categories"
                              element={<CategoryList />}
                        />
                        <Route
                              path="/dashboard/categories/create"
                              element={<CategoryCreate />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/edit"
                              element={<CategoryEdit />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/show"
                              element={<CategoryShow />}
                        />

                        {/* Unit */}
                        <Route
                              path="/dashboard/products/units"
                              element={<UnitList />}
                        />
                        <Route
                              path="/dashboard/products/units/create"
                              element={<UnitCreate />}
                        />
                        <Route
                              path="/dashboard/products/units/:unitId/edit"
                              element={<UnitEdit />}
                        />
                        <Route
                              path="/dashboard/products/units/:unitId/show"
                              element={<UnitShow />}
                        />

                        {/* Product */}
                        <Route
                              path="/dashboard/products"
                              element={<ProductList />}
                        />
                        <Route
                              path="/dashboard/products/create"
                              element={<ProductCreate />}
                        />
                        <Route
                              path="/dashboard/products/:productId/edit"
                              element={<ProductEdit />}
                        />
                        <Route
                              path="/dashboard/products/:productId/show"
                              element={<ProductShow />}
                        />
                        <Route
                              path="/dashboard/products/import/update"
                              element={<ProductImportUpdate />}
                        />
                        <Route
                              path="/dashboard/products/import/create"
                              element={<ProductImportCreate />}
                        />

                        {/* Image */}
                        <Route
                              path="/dashboard/images/create"
                              element={<ImageCreate />}
                        />

                        {/* Trending Product */}
                        <Route
                              path="/dashboard/trending_products/:productId/create"
                              element={<TrendingProductCreate />}
                        />
                        <Route
                              path="/dashboard/trending_products"
                              element={<TrendingProductList />}
                        />
                        <Route
                              path="/dashboard/trending_products/:productId/edit"
                              element={<TrendingProductEdit />}
                        />
                        <Route
                              path="/dashboard/trending_products/:productId/show"
                              element={<TrendingProductShow />}
                        />
                        <Route
                              path="/dashboard/trending_products/create"
                              element={<TrendingProductNew />}
                        />

                        {/* Brands */}
                        <Route
                              path="dashboard/brands"
                              element={<BrandList />}
                        />
                        <Route
                              path="dashboard/brands/create"
                              element={<BrandCreate />}
                        />
                        <Route
                              path="/dashboard/brands/:brandId/edit"
                              element={<BrandEdit />}
                        />
                        <Route
                              path="/dashboard/brands/manage/home/menu"
                              element={<BrandManage />}
                        />
                        <Route
                              path="/dashboard/brands/:brandId/show"
                              element={<BrandShow />}
                        />
                        <Route
                              path="/dashboard/brands/products/exports"
                              element={<BrandExport />}
                        />

                        {/* Blogs */}
                        <Route path="/dashboard/blogs" element={<BlogList />} />
                        <Route
                              path="/dashboard/blogs/create"
                              element={<BlogCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/edit"
                              element={<BlogEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/show"
                              element={<BlogShow />}
                        />

                        {/* Blog Category */}
                        <Route
                              path="/dashboard/blogs/categories"
                              element={<BlogCategoryList />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/create"
                              element={<BlogCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/edit"
                              element={<BlogCategoryEdit />}
                        />

                        {/* Job List */}
                        <Route path="/dashboard/jobs" element={<JobList />} />
                        <Route
                              path="/dashboard/jobs/create"
                              element={<JobCreate />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/edit"
                              element={<JobEdit />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/show"
                              element={<JobShow />}
                        />

                        {/* Quotation */}
                        <Route
                              path="/dashboard/quotations/:quotationId/edit"
                              element={<QuotationShow />}
                        />

                        <Route
                              path="/dashboard/quotations"
                              element={<QuotationList />}
                        />

                        <Route
                              path="/dashboard/quotations/products"
                              element={<QuotationProduct />}
                        />

                        <Route
                              path="/dashboard/quotations/checkout"
                              element={<QuotationCreate />}
                        />
                        <Route
                              path="/dashboard/quotations/pending/requests"
                              element={<QuotationPending />}
                        />
                        <Route
                              path="/dashboard/quotations/approved/requests"
                              element={<QuotationApproved />}
                        />
                        <Route
                              path="/dashboard/quotations/rejected/requests"
                              element={<QuotationRejected />}
                        />
                        <Route
                              path="/dashboard/quotations/cancelled/requests"
                              element={<QuotationCancelled />}
                        />
                        <Route
                              path="/dashboard/quotations/:quotationId/show"
                              element={<QuotationView />}
                        />

                        {/* Order */}
                        <Route
                              path="/dashboard/orders"
                              element={<AllOrder />}
                        />
                        <Route
                              path="/dashboard/orders/:orderId/show"
                              element={<OrderShow />}
                        />
                        {/* Pending Order */}
                        <Route
                              path="/dashboard/pending/orders"
                              element={<PendingOrder />}
                        />
                        <Route
                              path="/dashboard/pending/orders/:orderId/show"
                              element={<PendingShow />}
                        />

                        {/* Processing Order */}
                        <Route
                              path="/dashboard/processing/orders"
                              element={<ProcessingOrder />}
                        />
                        <Route
                              path="/dashboard/processing/orders/:orderId/show"
                              element={<ProcessingShow />}
                        />

                        {/* Rejected Order */}
                        <Route
                              path="/dashboard/rejected/orders"
                              element={<RejectedOrder />}
                        />
                        <Route
                              path="/dashboard/rejected/orders/:orderId/show"
                              element={<RejectedShow />}
                        />

                        {/* Cancelled Order */}
                        <Route
                              path="/dashboard/cancelled/orders"
                              element={<CancelledOrder />}
                        />
                        <Route
                              path="/dashboard/cancelled/orders/:orderId/show"
                              element={<CancelledShow />}
                        />

                        {/* Cancelled Order */}
                        <Route
                              path="/dashboard/shipping/orders"
                              element={<ShippingOrder />}
                        />
                        <Route
                              path="/dashboard/shipping/orders/:orderId/show"
                              element={<ShippingShow />}
                        />

                        {/* Delivered Order */}
                        <Route
                              path="/dashboard/delivered/orders"
                              element={<CompletedOrder />}
                        />
                        <Route
                              path="/dashboard/delivered/orders/:orderId/show"
                              element={<CompletedShow />}
                        />

                        {/* Dealers */}
                        <Route
                              path="/dashboard/dealers"
                              element={<DealerList />}
                        />
                        <Route
                              path="/dashboard/dealers/:dealerId/show"
                              element={<DealerShow />}
                        />

                        {/* Dealer Enquiry */}
                        <Route
                              path="/dashboard/dealer_enquiries"
                              element={<DealerEnquiryList />}
                        />
                        <Route
                              path="/dashboard/dealer_enquiries/:enquiryId/show"
                              element={<DealerEnquiryShow />}
                        />

                        {/* Dealer Requests */}
                        <Route
                              path="/dashboard/dealer_requests"
                              element={<DealerRequestList />}
                        />
                        <Route
                              path="/dashboard/dealer_requests/:requestId/show"
                              element={<DealerRequestShow />}
                        />

                        {/* Customer */}
                        <Route
                              path="/dashboard/customers"
                              element={<CustomerList />}
                        />
                        <Route
                              path="/dashboard/customers/create"
                              element={<CustomerCreate />}
                        />
                        <Route
                              path="/dashboard/customers/:customerId/edit"
                              element={<CustomerEdit />}
                        />
                        <Route
                              path="/dashboard/customers/:customerId/show"
                              element={<CustomerShow />}
                        />

                        {/* Customer Enquiry */}
                        <Route
                              path="/dashboard/customer-enquiries"
                              element={<CustomerEnquiryList />}
                        />
                        <Route
                              path="/dashboard/customer-enquiries/:customerEnquiryId/show"
                              element={<CustomerEnquiryShow />}
                        />

                        {/* Complain */}
                        <Route
                              path="/dashboard/complains"
                              element={<ComplainList />}
                        />
                        <Route
                              path="/dashboard/complains/:complainId/show"
                              element={<ComplainShow />}
                        />

                        {/* Complain Type */}
                        <Route
                              path="/dashboard/complains/types"
                              element={<ComplainTypeList />}
                        />
                        <Route
                              path="/dashboard/complains/types/create"
                              element={<ComplainTypeCreate />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/edit"
                              element={<ComplainTypeEdit />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/show"
                              element={<ComplainTypeShow />}
                        />

                        {/* Faq List */}
                        <Route path="/dashboard/faqs" element={<FaqList />} />
                        <Route
                              path="/dashboard/faqs/create"
                              element={<FaqCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/edit"
                              element={<FaqEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/show"
                              element={<FaqShow />}
                        />

                        {/* Faq Type */}
                        <Route
                              path="/dashboard/faqs/types"
                              element={<FaqTypeList />}
                        />
                        <Route
                              path="/dashboard/faqs/types/create"
                              element={<FaqTypeCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/edit"
                              element={<FaqTypeEdit />}
                        />

                        {/* Faq Request */}
                        <Route
                              path="/dashboard/faq_requests"
                              element={<FaqRequestList />}
                        />
                        <Route
                              path="/dashboard/faq_requests/:faqRequestId/show"
                              element={<FaqRequestShow />}
                        />
                        <Route
                              path="/dashboard/faq_requests/:faqId/edit"
                              element={<FaqRequestEdit />}
                        />

                        {/* Plugin */}
                        <Route
                              path="/dashboard/plugins"
                              element={<PluginList />}
                        />
                        <Route
                              path="/dashboard/plugins/create"
                              element={<PluginCreate />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/edit"
                              element={<PluginEdit />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/show"
                              element={<PluginShow />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Testimonial */}
                        <Route
                              path="/dashboard/testimonials"
                              element={<TestimonialList />}
                        />
                        <Route
                              path="/dashboard/testimonials/create"
                              element={<TestimonialCreate />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/edit"
                              element={<TestimonialEdit />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/show"
                              element={<TestimonialShow />}
                        />

                        {/* Subscriber */}
                        <Route
                              path="/dashboard/subscribers"
                              element={<SubscriberList />}
                        />
                        <Route
                              path="/dashboard/subscribers/create"
                              element={<SubscriberCreate />}
                        />
                        <Route
                              path="/dashboard/subscribers/:subscriberId/edit"
                              element={<SubscriberEdit />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />

                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        {/* Catalogue */}
                        <Route
                              path="/dashboard/catalogues"
                              element={<CatalogueList />}
                        />
                        <Route
                              path="/dashboard/catalogues/create"
                              element={<CatalogueCreate />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/edit"
                              element={<CatalogueEdit />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/show"
                              element={<CatalogueShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Size */}
                        <Route
                              path="/dashboard/products/sizes"
                              element={<SizeList />}
                        />
                        <Route
                              path="/dashboard/products/sizes/create"
                              element={<SizeCreate />}
                        />
                        <Route
                              path="/dashboard/products/sizes/:sizeId/edit"
                              element={<SizeEdit />}
                        />
                        <Route
                              path="/dashboard/products/sizes/:sizeId/show"
                              element={<SizeShow />}
                        />

                        {/* Colors */}
                        <Route
                              path="/dashboard/products/colors"
                              element={<ColorList />}
                        />
                        <Route
                              path="/dashboard/products/colors/create"
                              element={<ColorCreate />}
                        />
                        <Route
                              path="/dashboard/products/colors/:colorId/edit"
                              element={<ColorEdit />}
                        />
                        <Route
                              path="/dashboard/products/colors/:colorId/show"
                              element={<ColorShow />}
                        />

                        {/* Setting | Change Password | Quotation Setting */}
                        <Route
                              path="/dashboard/profile"
                              element={<Profile />}
                        />
                        <Route
                              path="/dashboard/change-password"
                              element={<ChangePassword />}
                        />

                        {/* Contact Details */}
                        <Route
                              path="/dashboard/contacts"
                              element={<ContactList />}
                        />
                        <Route
                              path="/dashboard/contacts/create"
                              element={<ContactCreate />}
                        />
                        <Route
                              path="/dashboard/contacts/:contactId/edit"
                              element={<ContactEdit />}
                        />
                        <Route
                              path="/dashboard/contacts/:contactId/show"
                              element={<ContactShow />}
                        />

                        {/* Showroom */}
                        <Route
                              path="/dashboard/showrooms"
                              element={<ShowroomList />}
                        />
                        <Route
                              path="/dashboard/showrooms/create"
                              element={<ShowroomCreate />}
                        />
                        <Route
                              path="/dashboard/showrooms/:showroomId/edit"
                              element={<ShowroomEdit />}
                        />
                        <Route
                              path="/dashboard/showrooms/:showroomId/show"
                              element={<ShowroomShow />}
                        />

                        {/* Showroom Collection */}
                        <Route
                              path="/dashboard/showroom_collections"
                              element={<ShowroomCollectionList />}
                        />
                        <Route
                              path="/dashboard/showroom_collections/create"
                              element={<ShowroomCollectionCreate />}
                        />
                        <Route
                              path="/dashboard/showroom_collections/:collectionId/edit"
                              element={<ShowroomCollectionEdit />}
                        />
                        <Route
                              path="/dashboard/showroom_collections/:collectionId/show"
                              element={<ShowroomCollectionShow />}
                        />

                        {/* Contact Request */}
                        <Route
                              path="/dashboard/contact_requests"
                              element={<ContactFormList />}
                        />
                        <Route
                              path="/dashboard/contact_requests/:contactId/show"
                              element={<ContactFormShow />}
                        />

                        {/* Shop */}
                        <Route
                              path="/dashboard/companies"
                              element={<CompanyList />}
                        />
                        <Route
                              path="/dashboard/companies/create"
                              element={<CompanyCreate />}
                        />
                        <Route
                              path="/dashboard/companies/:companyId/edit"
                              element={<CompanyEdit />}
                        />
                        <Route
                              path="/dashboard/companies/:companyId/show"
                              element={<CompanyShow />}
                        />

                        {/* Candidate */}
                        <Route
                              path="/dashboard/candidates"
                              element={<CandidateList />}
                        />
                        <Route
                              path="/dashboard/candidates/:candidateId/show"
                              element={<CandidateShow />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
