import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import NavMenu from "../../../components/Navigation/NavMenu";
import {
      Header,
      Sidebar,
      SidebarHeader,
      SidebarNav,
} from "../../../styles/layouts/navigation";

const Navigation = () => {
      const [navigate, setNavigate] = useState(false);

      const checkTokenExpiration = () => {
            const tokenExpiration = localStorage.getItem("tokenExpiration");
            if (!tokenExpiration || new Date(tokenExpiration) < new Date()) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("isLoggedIn");
                  localStorage.removeItem("tokenExpiration");

                  setNavigate(true);
            }
      };

      useEffect(() => {
            checkTokenExpiration();
            const intervalId = setInterval(checkTokenExpiration, 10000);
            return () => clearInterval(intervalId);
      }, []);

      if (navigate) {
            return <Navigate to="/" />;
      }

      return (
            <>
                  <Header>
                        <Sidebar>
                              <SidebarNav>
                                    <SidebarHeader>
                                          <Link to="/">
                                                <img
                                                      src="/images/logo.jpg"
                                                      alt="Bathnroom Official Logo"
                                                      className="img-fluid"
                                                />
                                          </Link>
                                    </SidebarHeader>
                                    <NavMenu />
                              </SidebarNav>
                        </Sidebar>
                  </Header>
            </>
      );
};

export default Navigation;
